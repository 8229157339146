<template>
  <div>
    <v-skeleton-loader
      v-if="isComponentLoading"
      class="mx-auto"
      width="100%"
      type="image"
    ></v-skeleton-loader>
    <v-carousel
      v-else
      cycle
      hide-delimiters
      hide-delimiter-background
      height="100%"
      :show-arrows="true"
      class=""
    >
      <v-carousel-item v-for="(slide, i) in sliderImageArray" :key="i">
        <!-- <v-sheet height="100%" color="white"> -->
        <!-- <v-row class="fill-height" align="center" justify="center"> -->
        <!-- <Img :src="slide.picPath" :width="'100%'" :containStatus="true" /> -->
        <v-img
          :src="`${slide.picPath}`"
          lazy-src="https://picsum.photos/id/11/100/60"
          width="100%"
          aspect-ratio="2.5"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <!-- </v-row> -->
        <!-- </v-sheet> -->
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import { HOST } from "../../config/api.js";

export default {
  beforeMount() {
    this.setCarouselHeightAccordingToScreenWidth();
  },
  beforeDestroy() {
    window.removeEventListener(
      "resize",
      this.setCarouselHeightAccordingToScreenWidth
    );
  },
  components: {},
  created() {
    window.addEventListener(
      "resize",
      this.setCarouselHeightAccordingToScreenWidth
    );
  },
  data() {
    return {
      // slider: [],
      height: 0,
      width: 0,
      carouselHeight: 0,
      HOST: HOST,
    };
  },
  methods: {
    setCarouselHeightAccordingToScreenWidth() {
      // this.carouselHeight = screen.width * (380 / 1280);
    },
  },
  props: {
    sliderImageArray: {
      type: Array,
      default: () => [
        // {
        //   path: contain
        //     "https://images.ctfassets.net/hrltx12pl8hq/1fR5Y7KaK9puRmCDaIof7j/09e2b2b9eaf42d450aba695056793607/vector1.jpg",
        // }
      ],
      required: true,
    },
    isComponentLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
